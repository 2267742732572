$color-black: #000000
$color-white: #ffffff
$color-gray-100: #f8f9fa
$color-gray-200: #f0f3f5
$color-gray-300: #eaedef
$color-gray-400: #cfd4d9
$color-gray-500: #acb5bd
$color-gray-600: #858e96
$color-gray-700: #404c5a
$color-gray-800: #21262a
$color-gray-900: #111517
$color-green: #2dde98
$color-green-washout: #c7ffe9
$color-green-dark: #20a46d
$color-aqua: #1cc7d0
$color-aqua-washout: #b8fbff
$color-aqua-dark: #0faab2
$color-electric: #00aeff
$color-electric-washout: #d7f2ff
$color-blue: #4885ef
$color-blue-washout: #d2eafb
$color-purple: #8e43e7
$color-magenta: #b84592
$color-yellow: #ffc168
$color-red: #e4002b
$color-red-washout: #fedfe5
$color-orange: orange
$color-orange-washout: #ffeedf
$color-background: #f0f3f5
$color-foreground: #ffffff
$color-nav-border: #f1f3f5

$nav-size-value: 60
$nav-size: #{$nav-size-value}px

$padding-app: 20px
$padding-nav: 20px

$sidebar-width-closed: $nav-size
$sidebar-width-open: 250px
$toolbar-item-height: 50px

$box-shadow-initial: 0 0 0 0 rgba(0,0,0,0)
$box-shadow-horizontal: 0 0 10px 10px rgba(0, 0, 0, 0.015)
$box-shadow-vertical: 2px 0 9px -1px rgba(0, 0, 0, 0.15)
$box-shadow-dialog-modal: 0px 0px 17px -3px rgba(0, 0, 0, 0.15)

@mixin transition-default
    transition: all 0.2s ease-out

$z-index-min: -1
$z-index-0: 0
$z-index-1: 1
$z-index-2: 2
$z-index-3: 3
$z-index-max: 20
$z-index-modal-1: 1000
$z-index-modal-2: 2000
$z-index-modal-3: 3000
