@import './sass/variables'
@import '@adtriba/ui/dist/index.css'

*
    font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif
    font-feature-settings: 'liga', 'kern'
    text-rendering: optimizeLegibility
    padding: 0px
    margin: 0px

html,
body,
.root
    width: 100%
    height: 100%
    background: $color-background
    margin: 0px
    padding: 0px

a
    text-decoration: none

.blur
    filter: blur(8px)
    pointer-events: none
    user-select: none

.d-flex
    display: flex

.flex-column
    flex-direction: column

.flex-row
    flex-direction: row

.justify-content-center
    justify-content: center

.align-items-center
    align-items: center

.gap-1
    gap: 0.25rem

.gap-2
    gap: 0.5rem

.gap-3
    gap: 0.75rem

.gap-4
    gap: 1rem

.gap-5
    gap: 1.25rem

.gap-6
    gap: 1.5rem

.pb-0
    padding-bottom: 0
