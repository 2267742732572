@import '../../../../sass/variables'

.toolbar-component
    display: flex
    flex-direction: column
    justify-content: center
    height: calc(100vh - #{$nav-size-value}px)
    background-color: $color-white
    overflow-x: hidden
    box-shadow: $box-shadow-initial

    &.sidebar
        &-open
            box-shadow: $box-shadow-vertical

        &[data-disabled=true]
            pointer-events: none
            user-select: none

.toolbar-component__container
    @include transition-default
    height: calc(100vh - #{$nav-size-value}px)
    width: $sidebar-width-closed
    height: 100%

    .sidebar-open &
        @include transition-default
        background-color: $color-gray-100
        width: $sidebar-width-open

    .toolbar-menu
        column-gap: 1.5rem
        height: $toolbar-item-height
        white-space: nowrap
        overflow-x: hidden

        &__item
            @include transition-default
            position: relative
            display: flex
            width: 100%
            height: 100%

            &:not(.sidebar-open &)
                &::after
                    @include transition-default
                    width: 0

            .sidebar-open &
                &::after
                    @include transition-default
                    top: 0
                    right: 0
                    height: $toolbar-item-height
                    width: 0.25rem

            &::after
                position: absolute
                transition: 0.3s ease-in-out
                content: ' '

            p,
            &:visited
                color: initial

            &[data-disabled='true']
                cursor: initial
                user-select: none

                p
                    color: $color-gray-400

            &:hover
                &::after
                    @include transition-default
                    position: absolute
                    top: 0
                    right: 0
                    height: $toolbar-item-height
                    width: 0.25rem
                    content: ' '

                &[data-disabled='true']
                    &::after
                        background-color: $color-gray-400

                &:not([data-disabled='true'])
                    @include transition-default
                    background-color: $color-white

                    svg
                        @include transition-default

                    &:not(.active)::after
                        background-color: $color-aqua

                    &.active::after
                        background-color: $color-aqua-dark
                    svg
                        @include transition-default
                        color: $color-aqua !important

                    p
                        @include transition-default
                        color: $color-aqua

            &.active
                p
                    color: $color-aqua-dark

                &::after
                    background-color: $color-aqua-dark

            .menu-item__inner-wrapper
                column-gap: 1.5rem
                padding: 0 1.25rem

                a
                    display: flex
                    flex-direction: column
                    align-items: center
