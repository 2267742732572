@import '../../../../sass/variables'

.spend-mapper-component .tooltip
    max-width: 200px
    white-space: -moz-pre-wrap !important
    white-space: -webkit-pre-wrap
    white-space: -pre-wrap
    white-space: -o-pre-wrap
    white-space: pre-wrap
    word-wrap: normal !important
    word-break: normal !important
    white-space: normal

.spend-mapper-component .modal:nth-child(1)
    z-index: $z-index-modal-3 !important

.spend-mapper-component .modal:nth-child(2)
    z-index: $z-index-modal-2 !important

.spend-mapper-component .modal:nth-child(3)
    z-index: $z-index-modal-1 !important

.spend-mapper-component .select__option-inner
    padding-bottom: 3px
