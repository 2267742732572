@import '../../sass/_variables'

.update-modal
    margin: auto
    border: 0 solid transparent
    border-radius: 5px
    box-shadow: $box-shadow-dialog-modal

    &:focus-visible
        outline: none

    &::backdrop
        background: rgba(0, 0, 0, 0.32)

    &-wrapper
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        gap: 1rem
        padding: 1.5rem 4rem 1rem

        .modal-content
            text-align: center
            line-height: 1.6

        .modal-buttons
            display: flex
            flex-direction: row
            justify-content: center
            gap: 1rem
            width: 100%
