@import '../../../../sass/variables'
    
.external-component
    height: 100%
    width: 100%
    position: absolute
    top: 0px
    left: 0px

.external-component iframe
    height: 100%
    width: 100%
    position: absolute
    top: 0px
    left: 0px
    border: none