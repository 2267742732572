@import '../../../../sass/variables'

.performance-component__table
    // to be added more here
    .view
        &.row
            padding: 0.5rem

.performance-component__table
    .view
        &.row
            .label
                padding: 0.1rem 0.5rem
                border-radius: 0.33rem

                &:not(.red):not(.green):not(.orange)
                    color: $color-gray-600

                &.green
                    color: $color-green-dark

.performance-component__table .label__text
    font-size: 0.813rem
    font-weight: 500

.performance-component__table td
    padding: 0.2rem 0 0.2rem 0 !important

.performance-component__conversion-delta-label > *
    font-weight: normal !important
