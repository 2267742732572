@import '../../../../sass/variables'

.customer-journey-component__super-divider
    background: #404c5a !important
    border-bottom: 0px !important

.customer-journey-component__super-divider .select__option-inner.menu
    color: white !important

.slider__tooltip
    min-width: 20px

.carousel__inner-journey
    .tooltip
        min-width: 180px !important
        width: fit-content !important

        span
            margin: 0 auto !important
