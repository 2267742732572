@import '../../../../sass/variables'

.nav-component
    background-color: white
    position: absolute
    z-index: $z-index-max
    top: 0
    left: 0
    width: 100%
    box-shadow: $box-shadow-horizontal
    height: $nav-size
    border-bottom: 1px solid $color-nav-border

    .update-button
        display: flex
        align-items: center
        justify-content: center
        margin-right: 1rem
        cursor: pointer
        animation: rotateAnimation 6s infinite ease-in-out

        &:hover
            svg
                fill: $color-aqua-dark

        @keyframes rotateAnimation
            0%
                transform: rotate(0deg)
            85%
                transform: rotate(0deg)
            100%
                transform: rotate(360deg)

.nav-component__menu .popup__content
    width: 300px
