@import '../../../../sass/variables'

.channel-mapper-component__draggable
    cursor: move
    position: relative
    box-sizing: border-box


.channel-mapper-component__draggable-spacer
    transition: height 1s
    background: #ebedef
