@import '../../../../sass/variables'

.spend-mapper-component .tooltip
    max-width: 200px
    white-space: -moz-pre-wrap !important
    white-space: -webkit-pre-wrap
    white-space: -pre-wrap
    white-space: -o-pre-wrap
    white-space: pre-wrap
    word-wrap: break-word
    word-break: break-all
    white-space: normal